import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Carbon Vue library</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Install</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Development</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Troubleshooting</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "resources"
    }}>{`Resources`}</h2>
    <Row mdxType="Row">
      <Column offsetLg="4" colLg="4" noGutterSm mdxType="Column">
  <ResourceCard title="Try Vue components with CodeSandbox." href="https://codesandbox.io/s/5zk452o9jp" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "95%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "codesandbox",
              "title": "codesandbox",
              "src": "/static/982215411e3a94425e6a2d19aa0bbd79/7d71f/codesandbox.png",
              "srcSet": ["/static/982215411e3a94425e6a2d19aa0bbd79/7d71f/codesandbox.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
    </Row>
    <h2 {...{
      "id": "carbon-vue-library"
    }}>{`Carbon Vue library`}</h2>
    <p>{`The `}<a parentName="p" {...{
        "href": "http://vue.carbondesignsystem.com/"
      }}>{`library`}</a>{` provides front-end developers & engineers a collection of reusable Vue components to build websites and user interfaces. Adopting the library enables developers to use consistent markup, styles, and behavior in prototype and production work.`}</p>
    <h2 {...{
      "id": "install"
    }}>{`Install`}</h2>
    <p>{`Assuming we’re starting with a new Vue CLI project:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`$ vue create my-project
Vue CLI v3.5.2
? Please pick a preset: default (babel, eslint)
$ cd my-project
`}</code></pre>
    <p>{`Using Yarn`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`$ yarn add @carbon/vue
`}</code></pre>
    <p>{`Or npm`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`$ npm install @carbon/vue
`}</code></pre>
    <p>{`In src/main.js add the following to include the carbon styles and components.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import 'carbon-components/css/carbon-components.css';
import CarbonComponentsVue from '@carbon/vue/src/index';
Vue.use(CarbonComponentsVue);
`}</code></pre>
    <p>{`Replace the contents of src/components/HelloWorld.vue with the following`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<template>
  <div class="sample">
    <h1>Example use of @carbon/vue</h1>
    <cv-text-input
      label="Who are you?"
      v-model="yourName"
      placeholder="your name"
    />
    <cv-button @click="onClick">Hello {{yourName}}</cv-button>
    <cv-modal :visible="visible" @modal-hidden="modalClosed">
      <template slot="title"
        >Welcome to @carbon/vue {{yourName}}</template
      >
      <template slot="content">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, seed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p>
      </template>
    </cv-modal>
  </div>
</template>

<script>
  export default {
    name: 'HelloWorld',
    data() {
      return {
        yourName: '',
        visible: false,
      };
    },
    methods: {
      onClick() {
        this.visible = true;
      },
      modalClosed() {
        this.visible = false;
      },
    },
  };
</script>

<style>
  .sample {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    margin: 5% auto;
  }

  .cv-text-input {
    margin: 30px 0;
  }
</style>
`}</code></pre>
    <p>{`That’s it! Now start the server and start building.`}</p>
    <p>{`Using Yarn`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`$ yarn serve
`}</code></pre>
    <p>{`Or npm`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`$ npm serve
`}</code></pre>
    <p><em parentName="p">{`Note: This isn’t the only way to bootstrap a`}</em>{` `}<inlineCode parentName="p">{`carbon-components-vue`}</inlineCode>{` `}<em parentName="p">{`application, but the combination of`}</em>{` `}<inlineCode parentName="p">{`Vue CLI`}</inlineCode>{` `}<em parentName="p">{`and the`}</em>{` `}<inlineCode parentName="p">{`carbon-components`}</inlineCode>{` `}<em parentName="p">{`scss is our recommended setup.`}</em></p>
    <h3 {...{
      "id": "list-of-available-components"
    }}>{`List of available components`}</h3>
    <p>{`View available Vue Components `}<a parentName="p" {...{
        "href": "http://vue.carbondesignsystem.com"
      }}>{`here`}</a>{`. Usage information is available in the notes provided with each story.`}</p>
    <h2 {...{
      "id": "troubleshooting"
    }}>{`Troubleshooting`}</h2>
    <p>{`If you experience any issues while getting set up with Carbon Components Vue, please head over to the `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-components-vue"
      }}>{`GitHub repo`}</a>{` for more guidelines and support. Please `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-components-vue/issues"
      }}>{`create an issue`}</a>{` if your issue does not already exist.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      